import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface SortState {
  value: string;
}

const initialState: SortState = {
  value: 'Flight Price'
};


const sortSlice = createSlice({
  name: 'sort',
  initialState,
  reducers: {
    // Use a generic to enforce the value type to match the key
    updateSortValue: (
      state: SortState,
      action: PayloadAction<string>
    ) => {
      state.value = action.payload;
      console.log(action.payload)
    },
  }
});

export const { updateSortValue } = sortSlice.actions;
export default sortSlice.reducer;
