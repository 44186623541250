import Box from '@mui/material/Box';
import { UseSelector, useSelector } from 'react-redux';
import { Stack, Typography, Divider } from "@mui/material";
import SliderSection from "./SliderSection";
import { RootState } from "../../store/store";
import { updateInputValue } from '../../store/filterReducer';

// import CheckboxSection from "./CheckboxSection";

// const checkboxes = [
//   {
//     category: "Region", types: ['Europe', 'South America',
//       'Africa', 'Asia', 'Australia & New Zealand',
//       'Carribean', 'Central America', 'Middle East',
//       'North America', 'Oceania']
//   },
//   // { category: "Country", types: ["France", "Argentina", "Kenia", "Vietnam", "Costa Rica"] },
// ];



function Filters() {
  const filterData = useSelector((state : RootState) => state.filter);
  const sliders = [
    { category: "Price", min: 0, max: 10000, unit: "€", minvalue: filterData.minPrice, maxvalue: filterData.maxPrice},
    { category: "Distance to Airport", min: 0, max: 300, unit: "km", value: filterData.airportDist },
    { category: "Temperature", min: 10, max: 40, unit: "°C", minvalue: filterData.minTemp, maxvalue: filterData.maxTemp },
    { category: "Wind Speed (knots)", min: 12, max: 28, unit: "kn", minvalue: filterData.minWind, maxvalue: filterData.maxWind },
  ];
  console.log(filterData, "fitlerDataUpdated")
  return (
    <Box py={{ xs: 2, lg: 0 }} px="10px">
      <Box py={1} mb={3}>
        <Typography variant="body1" component='p' fontWeight="bold">Filters</Typography>
      </Box>
      <Stack divider={<Divider flexItem />} spacing={2}>
        {sliders?.map((item, index) => (
          <SliderSection key={index} category={item.category} min={item.min} max={item.max} unit={item.unit} value={item.value??0} maxvalue={item.maxvalue??0} minvalue={item.minvalue??0} ></SliderSection>
        ))}
        {/* {checkboxes?.map((item, index) => (
          <CheckboxSection key={index} category={item.category} types={item.types}></CheckboxSection>
        ))} */}
      </Stack>
    </Box>
  );
}

export default Filters;
