import { configureStore } from '@reduxjs/toolkit';
import inputReducer from './filterReducer';
import resultReducer from './resultReducer';
import sortReducer from './sortReducer';
import filterMemoReducer from './filterMemoReducer';
import currentPageReducer from './currentPageReducer';
import updateLoadingState from './loadingReducer';
import updatesearchState  from './searchReducer';

const store = configureStore({
  reducer: {
    filter: inputReducer,
    filterMemo: filterMemoReducer,
    result: resultReducer,
    sort: sortReducer,
    page: currentPageReducer,
    loading: updateLoadingState,
    search: updatesearchState
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;