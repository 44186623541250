import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FilterResult {
  spotName: string,
  price: number,
  flights: any,
  score: number,
  duration: string,
  description: any,
  airportDist: number,
  depAirportIata: string,
  arrAirportIata: string,
  region: string
}

const initialState: FilterResult[] = [{
  "spotName": "Aruba",
  "price": 1010.000000,
  "duration": "49:05",
  "flights": {
    "price": 1010,
    "deal": "cheapest",
    "duration": "49:05",
    "details": [
      {
        "from": "FRA",
        "to": "AUA",
        "duration": "28:35",
        "departureTime": "19:20",
        "arrivalTime": "17:55",
        "stops": 1
      },
      {
        "from": "AUA",
        "to": "FRA",
        "duration": "20:30",
        "departureTime": "19:15",
        "arrivalTime": "21:45",
        "stops": 1
      }
    ]
  },
  "description": {
    "preview": "Aruba offers year-round kitesurfing with its strong and consistent trade winds. The island's flat water locations and offshore reefs provide ideal conditions for all skill levels.",
    "wind_conditions": "Reliable trade winds throughout the year, peaking from April to August.",
    "water_conditions": "Flat water lagoons for beginners and freestyle, with wave spots for the more adventurous.",
    "accessibility": "High, with kitesurfing spots easily reached from anywhere on the island and numerous schools offering lessons and equipment.",
    "picture": "img.jpg",
    "hazards": {
      "offshore_winds": "Some popular spots have offshore winds, making rescue services important for beginners.",
      "underwater_hazards": "Be aware of coral reefs and sea urchins in some areas."
    }
  },
  "airportDist": 1,
  "depAirportIata": "FRA",
  "arrAirportIata": "AUA",
  "region": "Carribean",
  "score": 95.903955,
}];

const filterResultSlice = createSlice({
  name: 'result',
  initialState,
  reducers: {
    updateFilterResult: (state, action: PayloadAction<FilterResult[]>) => {
      state.splice(0, state.length, ...action.payload);
    },
  }
});
export const { updateFilterResult } = filterResultSlice.actions;
export default filterResultSlice.reducer;
