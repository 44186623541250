import { ChangeEvent, useEffect, useState } from "react";
import "../../assets/styles/SharedStyles.css";
import CardComponent from "../../components/card/CardComponent";
import Filters from "./Filters";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Stack, Typography, IconButton } from "@mui/material";
import Pagination from '@mui/material/Pagination';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { FilterResult } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import { updateFilterResult } from "../../store/resultReducer";
import { RootState } from "../../store/store";
import { updateSortValue } from "../../store/sortReducer";
import { updateCurrentPage } from "../../store/currentPageReducer";
import Loading from "../../components/loading/loading";
// import PrivacyModal from "../../components/privacymodal/privacy_modal";
// import AdComponent from "../../components/AdComponent";

type props = {
  skyType: number;
  filterResult: FilterResult[];
}
const HomePage: React.FC<props> = (props) => {
  const dispatch = useDispatch();
  const sortValue = useSelector((state: RootState) => state.sort).value;
  const currentPage = useSelector((state: RootState) => state.page).value;
  const loadingState = useSelector((state: RootState) => state.loading).value;
  const searchState = useSelector((state: RootState) => state.search).value;
  const PAGE_SIZE = 5;
  const [filterShow, setFilterShow] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  const [paginatedData, setPaginatedData] = useState<FilterResult[]>([]);

  const handleChange = (event: SelectChangeEvent) => {
    dispatch(updateSortValue(event.target.value as string))
  };
  const handlePageChange = (event: ChangeEvent<unknown>, page: number) => {
    dispatch(updateCurrentPage(page))
  }
  const sortData = (data: FilterResult[], criteria: string) => {
    switch (criteria) {
      case 'Flight Price':
        return [...data].sort((a, b) => a.price - b.price);
      case 'Conditions':
        return [...data].sort((a, b) => b.score - a.score);
      case 'Airport Distance':
        return [...data].sort((a, b) => a.airportDist - b.airportDist);
      default:
        return data;
    }
  }
  const uniqueSpotNames: string[] = [];
  props.filterResult.map((item) => {
    if (!uniqueSpotNames.includes(item.spotName)) {
      uniqueSpotNames.push(item.spotName);
    }
    return uniqueSpotNames;
  });

  // Get the length of the unique spotName array
  const uniqueSpotNameCount = uniqueSpotNames.length;
  useEffect(() => {
    const sortedData = sortData(props.filterResult, sortValue)
    const startIndex = (currentPage - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;
    setPaginatedData(sortedData.slice(startIndex, endIndex))
    dispatch(updateFilterResult(sortedData));
    // else if (props.skyType === 1)
    //   setSkyriders(skyRidersData.surf);
    // else if (props.skyType === 2)
    //   setSkyriders(skyRidersData.ski);
  }, [currentPage, sortValue, props.filterResult, dispatch]);

  return (
    <Box className="page" sx={{ maxWidth: "1400px", mx: "auto", py: { xs: 3, sm: 4, md: 6, lg: 8 } }}>
      <Grid container >
        <Grid
          className="scrollbar"
          item lg={3}
          pl={{ xs: 2, lg: 0 }}
          pr={{ xs: 3, lg: 5 }}
          mx="-10px"
          sx={{
            width: '100%',
            maxWidth: '280px',
            backgroundColor: '#fff',
            position: { xs: 'fixed', lg: 'initial' },
            top: 0,
            left: `${!filterShow ? '-100%' : '0'}`,
            height: { xs: '100vh', lg: 'auto' },
            overflowY: "auto",
            zIndex: '12',
            transition: 'left ease .6s'
          }}
        >
          <Filters />
        </Grid>
        <Grid item xs={12} lg={9} pl={{ xs: 0, lg: 3 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" mb={{ xs: 2, md: 3 }}>
            <Box display="flex" alignItems="center">
              {!loadingState &&
                <Box sx={{ display: 'flex', }}>
                  <Typography fontWeight="bold" sx={{ mr: 1, fontSize: '18px' }}>{searchState ? uniqueSpotNameCount : ""}</Typography>
                  <Typography fontWeight="bold" sx={{ fontSize: '18px' }}>{searchState ? "destinations" : ""}</Typography>
                </Box>}
              <IconButton
                sx={{ zIndex: 1, display: { xs: 'flex', lg: 'none' } }}
                aria-label="filterIconBtn" color="secondary"
                onClick={() => { setFilterShow(true) }}>
                <FilterAltIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ px: 2 }} variant="body2" component="p" >Sort by</Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // renderValue={() => {
                //   return value;
                // }}
                value={sortValue}
                onChange={handleChange}
                sx={{ '& .MuiSelect-select': { py: 1 }, '& fieldset': { borderColor: "rgba(0, 0, 0, 0.23) !important" } }}
              >
                <MenuItem value='Flight Price'>Flight Price</MenuItem>
                <MenuItem value='Airport Distance'>Airport Distance</MenuItem>
                <MenuItem value='Conditions'>Conditions</MenuItem>
              </Select>
            </Box>
          </Stack>
          {loadingState ? <Loading /> : paginatedData.length > 0 ? paginatedData.map((ele: FilterResult, index: number) =>
            <CardComponent
              key={index}
              index={index}
              pageNum={currentPage}
              flights={ele.flights}
              spotName={ele.spotName}
              duration={ele.duration}
              description={ele.description}
              airportDist={ele.airportDist}
              depAirportIata={ele.depAirportIata}
              arrAirportIata={ele.arrAirportIata}
              region={ele.region}
            />
          ) :
            <Box py={4} textAlign="center">
              <Typography variant="body1" component="p">{searchState ? "No results" : ""}</Typography>
            </Box>
          }
          {
            loadingState || props.filterResult.length > 0 && <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Pagination page={currentPage} count={Math.ceil(props.filterResult.length / PAGE_SIZE)} onChange={handlePageChange} variant="outlined" shape="rounded" />
            </Box>
          }
        </Grid>
      </Grid>
      <Box
        sx={{
          display: `${filterShow ? 'block' : 'none'}`,
          position: 'fixed',
          left: '0', top: '0',
          width: '100%', height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.5)'
        }}
        onClick={() => { setFilterShow(false) }}
      />
      {/* <PrivacyModal /> */}
      {/* <AdComponent /> */}

    </Box >
  );
}

export default HomePage;
