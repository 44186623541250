import * as React from "react";
import SliderComponent from "../../components/slider/SliderComponent";
import RangeSliderComponent from "../../components/rangeslider/RangeSliderComponent";
import { Stack, Typography } from "@mui/material";
type props = {
  category: string;
  min: number;
  max: number;
  unit: string;
  value: number;
  maxvalue: number;
  minvalue: number;
};

const SliderSection: React.FC<props> = ({ category, min, max, unit, value, minvalue, maxvalue }) => {
  return (
    <Stack>
      <Typography variant="body2" fontWeight='bold'>{category}</Typography>
      {
        (category.includes('Distance')) ?
          <SliderComponent min={min} max={max} unit={unit} value={value}></SliderComponent>
          :
          <RangeSliderComponent min={min} max={max} unit={unit} category={category} minvalue={minvalue} maxvalue={maxvalue}></RangeSliderComponent>
      }
    </Stack>
  );
}

export default SliderSection;
