import dayjs, { Dayjs } from 'dayjs';

// interface AppState {
//   depAirport: string;
//   minWind: number;
//   maxWind: number;
//   minTemp: number;
//   maxTemp: number;
//   minPrice: number;
//   maxPrice: number;
//   airportDist: number;
//   region: string[];
//   depDate: string;
//   retDate: string;
// }

const today = dayjs();
const defaultDepDate = today.add(7, 'day');
const defaultRetDate = today.add(14, 'day');

export {defaultDepDate, defaultRetDate};