import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface currentPageState {
  value: number;
}

const initialState: currentPageState = {
  value: 1
};


const currentPageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    // Use a generic to enforce the value type to match the key
    updateCurrentPage: (
      state: currentPageState,
      action: PayloadAction<any>
    ) => {
      state.value = action.payload;
      console.log(action.payload)
    },
  }
});

export const { updateCurrentPage } = currentPageSlice.actions;
export default currentPageSlice.reducer;
