// @mui
import { Box, Grid, Typography } from "@mui/material";
// components

// ----------------------------------------------------------------------
export default function ContactPage() {
  return (
    <Box px={2} sx={{ minHeight: 'calc(100vh - 168px)' }}>
      <Box py={{ xs: 4, sm: 8 }} sx={{ maxWidth: "1200px", mx: "auto" }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={7} >
            <Box py={6}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography component="p" variant="h6" color='#808080' >email:&nbsp;</Typography>
                <Typography component="a" variant="h6" href="mailto:kitetripscanner@softbasis.net" sx={{ color: '#33004A' }}>kitetripscanner@softbasis.net</Typography>
              </Box>
              <Typography component="p" variant="h6" color='#808080' mb={5} >
                Soft Basis<br />
                48 rue du Sauzai bat. D <br />
                38110 La Tour du Pin<br />
                VAT: FR58817452642
              </Typography>
              <Typography component="p" variant="h6" color='#808080' >
                You want to add your spot? You have suggestions or found a bug? You have a project and you need to develop it? Do not
                hesitate to get in touch.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={5} >
            <Box component='img' src="/assets/images/Cabarete_0.jpg" alt="contact_image" sx={{ width: '100%' }}></Box>
          </Grid>
        </Grid>
      </Box>
    </Box >
  );
}

