import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Slider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import "./SliderComponent.css";
import { updateInputValue } from '../../store/filterReducer';

const PrettoSlider = styled(Slider)({
  color: '#52af77',
  height: 4,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 15,
    width: 15,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&::before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

type props = {
  min: number;
  max: number;
  unit: string;
  value: number;
};

const SliderComponent: React.FC<props> = ({ min, max, unit, value }) => {
  const dispatch = useDispatch();
  const handleDistChange = (event: Event, newValue: number | number[]) => {
    const actionPayload: any = {
      name: 'airportDist',
      value: newValue
    }
    dispatch(updateInputValue(actionPayload))
  }

  return (
    <Box sx={{ py: 1 }}>
      <Box sx={{ display: 'flex' }}>
        <Typography sx={{ mr: 1, mt: 1, opacity: '0.7' }} variant="body2">{min}</Typography>
        <Typography sx={{ mr: 1, mt: 1, opacity: '0.7' }} variant="body2">-</Typography>
        <Typography sx={{ mr: 1, mt: 1, opacity: '0.7' }} variant="body2">{max}</Typography>
        <Typography sx={{ mr: 1, mt: 1, opacity: '0.7' }} variant="body2">{unit}</Typography>
      </Box>
      <Box sx={{ px: 1 }}>
        <PrettoSlider
          valueLabelDisplay="auto"
          min={min}
          max={max}
          getAriaLabel={() => 'pretto slider'}
          value={value}
          onChange={handleDistChange}
        />
      </Box>
    </Box>
  );
}

export default SliderComponent;
