import { Box, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

function Bottom() {
  return (
    <Box sx={{
      backgroundImage: 'linear-gradient(135deg, #33004A, #0E0958)',
      px: { xs: 2, md: 4 },
      py: 2,
      '& .MuiTabs-indicatorSpan': {
        backgroundColor: '#00C6EE !important'
      }
    }}>
      <Stack sx={{ maxWidth: "1400px", mx: "auto" }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center">
          <Box sx={{ display: 'flex' }}>
            <Link to='/privacy_policy' style={{ display: 'flex', marginRight: '10px', textDecoration: 'none' }}>
              <Typography component='p' variant="body2" color="white">Privacy Policy</Typography>
            </Link>
            <Link to='/terms' style={{ display: 'flex', marginRight: '10px', textDecoration: 'none' }}>
              <Typography component='p' variant="body2" color="white">Terms and Conditions</Typography>
            </Link>
            <Box sx={{ px: 1 }}>
              <Typography component='p' variant="body2" color="white">Powered by</Typography>
            </Box>
            <Link to='contact' style={{ display: 'flex' }}>
              <Box component='img' width='24px' height='24px' src="/logo/logo_simple.svg" alt="logo" />
            </Link>
          </Box>
        </Stack>
        <Box>
        </Box>
      </Stack >
    </Box >
  );
}

export default Bottom;
