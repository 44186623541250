import * as React from 'react';
import './loading.css';
const Loading: React.FC = () => {
    return (
            <div className="loading-container">
                <div className="loader">
                    <div className="loading-spinning-bubbles">
                        <div className="bubble-container">
                            <div className="bubble"></div>
                        </div>
                        <div className="bubble-container">
                            <div className="bubble"></div>
                        </div>
                        <div className="bubble-container">
                            <div className="bubble"></div>
                        </div>
                        <div className="bubble-container">
                            <div className="bubble"></div>
                        </div>
                        <div className="bubble-container">
                            <div className="bubble"></div>
                        </div>
                        <div className="bubble-container">
                            <div className="bubble"></div>
                        </div>
                        <div className="bubble-container">
                            <div className="bubble"></div>
                        </div>
                        <div className="bubble-container">
                            <div className="bubble"></div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Loading;