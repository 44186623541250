import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface searchState {
  value: boolean;
}

const initialState: searchState = {
  value: false
};


const searchStateSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    // Use a generic to enforce the value type to match the key
    updatesearchState: (
      state: searchState,
      action: PayloadAction<any>
    ) => {
      state.value = action.payload;
      console.log(action.payload)
    },
  }
});

export const { updatesearchState } = searchStateSlice.actions;
export default searchStateSlice.reducer;
