import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Slider, Typography, OutlinedInput } from '@mui/material';
import { styled } from '@mui/material/styles';

import "./RangeSliderComponent.css";
import { updateInputValue } from '../../store/filterReducer';

const PrettoSlider = styled(Slider)({
  color: '#52af77',
  height: 4,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 15,
    width: 15,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&::before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

function valuetext(value: number) {
  return `${value}`;
}

type props = {
  min: number;
  max: number;
  unit: string;
  category: string;
  maxvalue: number;
  minvalue: number;
};

const RangeSliderComponent: React.FC<props> = ({ min, max, unit, category, minvalue, maxvalue }) => {
  const dispatch = useDispatch();
  // const filterData = useSelector((state: RootState) => state);

  const [value, setValue] = React.useState<number[]>([min, max]);

  const handleSliderChange = (event: Event, newValue:number[] | number ) => {
    console.log(newValue)
    let new_value = newValue as number[];
    let actionPayload: any;
    switch (category) {
      case "Price":
        actionPayload = {
          name: 'maxPrice',
          value: new_value[1]
        };
        break;
      case "Wind Speed (knots)":
        actionPayload = {
          name: 'maxWind',
          value: new_value[1]
        };
        break;
      case "Temperature":
        actionPayload = {
          name: 'maxTemp',
          value: new_value[1]
        };
        break;
      default:
        actionPayload = {
          name: 'maxPrice',
          value: new_value[1]
        };
    }
    let min_actionPayload: any;

    switch (category) {
      case "Price":
        min_actionPayload = {
          name: 'minPrice',
          value: new_value[0]
        };
        break;
      case "Wind Speed (knots)":
        min_actionPayload = {
          name: 'minWind',
          value: new_value[0]
        };
        break;
      case "Temperature":
        min_actionPayload = {
          name: 'minTemp',
          value: new_value[0]
        };
        break;
      default:
        min_actionPayload = {
          name: 'minPrice',
          value: new_value[0]
        };
    }

    dispatch(updateInputValue(min_actionPayload))
    dispatch(updateInputValue(actionPayload))
    // dispatch(updateInputValue(newValue))
  };

  // const handleSliderChange = (event: Event, newValue: number | number[]) => {
  //   const actionPayload: any = {
  //     name: 'test',
  //     value: newValue
  //   }
  //   dispatch(updateInputValue(actionPayload))
  // }

  const handleMinInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: number = Number(event.target.value);
    const tempValue: number = value[1];
    // setValue([newValue, 5])
    setValue([newValue, tempValue] as number[]);
    // setValue(newValue === '' ? [min, tempValue] : [newValue, tempValue] as number[]);
  };

  const handleMaxInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var newValue = Number(event.target.value);
    var tempValue = value[0];
    setValue([tempValue, newValue] as number[]);
    // setValue(newValue === '' ? [tempValue, max] : [tempValue, newValue] as number[]);
  };

  return (
    <Box sx={{ py: 1 }}>
      <Box sx={{ display: 'flex' }}>
        <Typography sx={{ mr: 1, mt: 1, opacity: '0.7' }} variant="body2">Min</Typography>
        <OutlinedInput
          value={minvalue}
          size="small"
          onChange={handleMinInputChange}
          inputProps={{
            step: 10,
            min: min,
            max: maxvalue,
            type: 'number',
            'aria-labelledby': 'input1-slider',
          }}
        />
        <Typography sx={{ mx: 1, mt: 1, opacity: '0.7' }} variant="body2">-</Typography>
        <Typography sx={{ mr: 1, mt: 1, opacity: '0.7' }} variant="body2">Max</Typography>
        <OutlinedInput
          value={maxvalue}
          size="small"
          onChange={handleMaxInputChange}
          inputProps={{
            step: 10,
            min: minvalue,
            max: max,
            type: 'number',
            'aria-labelledby': 'input2-slider',
          }}
        />
        <Typography sx={{ mx: 1, mt: 1, opacity: '0.7' }} variant="body2">{unit}</Typography>
      </Box>
      <Box sx={{ px: 1 }}>
        <PrettoSlider
          valueLabelDisplay="auto"
          min={min}
          max={max}
          getAriaLabel={() => 'pretto slider range'}
          value={[minvalue, maxvalue]}
          onChange={handleSliderChange}
          getAriaValueText={valuetext}
          disableSwap
        />
      </Box>
    </Box>
  );
}

export default RangeSliderComponent;
