import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Box, Stack, Typography } from "@mui/material";
import { RootState } from '../../store/store';
// components

// ----------------------------------------------------------------------
export default function DetailPage() {
  const resultData = useSelector((state: RootState) => state.result);
  const { num } = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [])


  return (
    <Box position="relative" sx={{ height: { xs: "80vh", md: "auto" }, display: "flex" }}>
      <Box component="img" src={`https://kitetripscanner.com/assets/images/fullResSpots/${resultData[Number(num) - 1].description.picture}`} sx={{ width: "100%", height: "100%", objectFit: "cover" }} />
      <Box sx={{
        position: "absolute",
        left: 0, top: 0,
        width: "100%",
      }}>
        <Stack direction={{ xs: "column", md: "row" }} gap={{ xs: 4, md: 0 }} justifyContent="space-between"
          sx={{
            maxWidth: "1600px",
            mx: "auto", py: 10,
            px: 2
          }}
        >
          <Box
            sx={{
              maxWidth: "450px",
              backgroundColor: "#310B54",
              borderRadius: "24px",
              p: 4,
              color: "#fff"
            }}>
            <Typography variant="body1" mb={4}>
              {resultData[Number(num) - 1].description.preview}
            </Typography>
            <Box mb={4}>
              <Typography component="p" variant='body1' fontWeight="bold" mb={1} >Features</Typography>
              <Box component="ul" sx={{ my: 0, paddingLeft: "20px" }}>
                <Box component="li" mb={1}>{resultData[Number(num) - 1].description.wind_conditions}</Box>
                <Box component="li" mb={1}>{resultData[Number(num) - 1].description.water_conditions}</Box>
                <Box component="li">{resultData[Number(num) - 1].description.accessibility}</Box>
              </Box>
            </Box>
            <Box mb={4}>
              <Typography component="p" variant='body1' fontWeight="bold" mb={1} >Hazards</Typography>
              <Box component="ul" sx={{ my: 0, paddingLeft: "20px" }}>
                {
                  Object.entries(resultData[Number(num) - 1].description.hazards).map(([key, value]) => (
                    <Box key={key} component="li" mb={1}>{resultData[Number(num) - 1].description.hazards[key]}</Box>
                  ))
                }
                {/* <Box component="li" mb={1}>{resultData[Number(num) - 1].description.hazards.underwater_hazards}</Box> */}
              </Box>
            </Box>
            {/* <Typography component="p" variant='body1'>
            Best Months for Kitesurfing<br />
            The optimal time for kitesurfing is from May to September,<br />
            when the wind is most reliable.
          </Typography> */}
          </Box>
          <Box
            sx={{
              mb: "auto",
              maxWidth: "300px",
              backgroundColor: "#310B54",
              borderRadius: "16px",
              p: 2,
              color: "#fff"
            }}>
            <Typography variant="body1">
              {Math.ceil(resultData[Number(num) - 1].score)}% days in the selected period match your conditions
            </Typography>
          </Box>
        </Stack>
      </Box>

    </Box>
  );
}

