import { Box } from '@mui/material';
import React from 'react';

const FlightLineIcon: React.FC<{ stopNum: number }> = (props) => {
    let icon = null;

    switch (props.stopNum) {
        case 1:
            icon = (
                <svg width="100" height="6" viewBox="0 0 100 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line y1="3" x2="100" y2="3" stroke="#626971" strokeWidth="2" />
                    <rect x="44" width="12" height="6" fill="white" />
                    <circle cx="50" cy="3" r="3" fill="#E70866" />
                </svg>
            );
            break;
        case 2:
            icon = (
                <svg width="100" height="6" viewBox="0 0 100 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line y1="3" x2="100" y2="3" stroke="#626971" strokeWidth="2" />
                    <rect x="52" width="12" height="6" fill="white" />
                    <circle cx="58" cy="3" r="3" fill="#E70866" />
                    <rect x="36" width="12" height="6" fill="white" />
                    <circle cx="42" cy="3" r="3" fill="#E70866" />
                </svg>
            );
            break;
        case 3:
            icon = (<svg width="100" height="6" viewBox="0 0 100 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line y1="3" x2="100" y2="3" stroke="#626971" strokeWidth="2" />
                <rect x="60" width="12" height="6" fill="white" />
                <circle cx="66" cy="3" r="3" fill="#E70866" />
                <rect x="44" width="12" height="6" fill="white" />
                <circle cx="50" cy="3" r="3" fill="#E70866" />
                <rect x="28" width="12" height="6" fill="white" />
                <circle cx="34" cy="3" r="3" fill="#E70866" />
            </svg>)
            break;
        case 4:
            icon = (<svg width="100" height="6" viewBox="0 0 100 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line y1="3" x2="100" y2="3" stroke="#626971" strokeWidth="2" />
                <rect x="68" width="12" height="6" fill="white" />
                <circle cx="74" cy="3" r="3" fill="#E70866" />
                <rect x="52" width="12" height="6" fill="white" />
                <circle cx="58" cy="3" r="3" fill="#E70866" />
                <rect x="36" width="12" height="6" fill="white" />
                <circle cx="42" cy="3" r="3" fill="#E70866" />
                <rect x="20" width="12" height="6" fill="white" />
                <circle cx="26" cy="3" r="3" fill="#E70866" />
            </svg>)
            break;
        case 5:
            icon = (<svg width="100" height="6" viewBox="0 0 100 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line y1="3" x2="100" y2="3" stroke="#626971" strokeWidth="2" />
                <rect x="76" width="12" height="6" fill="white" />
                <circle cx="82" cy="3" r="3" fill="#E70866" />
                <rect x="60" width="12" height="6" fill="white" />
                <circle cx="66" cy="3" r="3" fill="#E70866" />
                <rect x="44" width="12" height="6" fill="white" />
                <circle cx="50" cy="3" r="3" fill="#E70866" />
                <rect x="28" width="12" height="6" fill="white" />
                <circle cx="34" cy="3" r="3" fill="#E70866" />
                <rect x="12" width="12" height="6" fill="white" />
                <circle cx="18" cy="3" r="3" fill="#E70866" />
            </svg>)
            break;
        default:
            icon = (
                <svg width="100" height="6" viewBox="0 0 100 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line y1="3" x2="100" y2="3" stroke="#626971" strokeWidth="2" />
                </svg>
            );
    }

    return <Box display="flex">{icon}</Box>;
}

export default FlightLineIcon;
