import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface loadingState {
  value: boolean;
}

const initialState: loadingState = {
  value: false
};


const loadingStateSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    // Use a generic to enforce the value type to match the key
    updateLoadingState: (
      state: loadingState,
      action: PayloadAction<any>
    ) => {
      state.value = action.payload;
      console.log(action.payload)
    },
  }
});

export const { updateLoadingState } = loadingStateSlice.actions;
export default loadingStateSlice.reducer;
